import { ItemReorderEventDetail } from "@ionic/core";
import deepEqual from "deep-equal";
import { FormattedMessage, IntlShape } from "react-intl";

import Tool from "@components/v1/carePlan/Tool";
import DateStamp from "@components/v1/dates/DateStamp";
import { TagProps } from "@components/v1/labels/Tag";
import Nbsp from "@components/v1/Nbsp";
import ExternalLink from "@components/v1/typography/ExternalLink";
import InternalLink from "@components/v1/typography/InternalLink";
import { oneDayInMs } from "@typing/Constants";
import {
  ContentConditionSourceEnum,
  InitialConsultFieldEnum,
  JourneyStatusEnum,
  OtherActivityKindsEnum,
  PersonalizeExperienceFieldEnum,
  SortByTimestampDirectionEnum,
  StartTimeValuesEnum,
  TimePeriodEnum
} from "@typing/Enums";
import {
  ActivityKindEnum,
  ActivityFragment as ActivityType,
  ArticleContentTypeEnum,
  ArticleFragment,
  CarePlanActionItemFragment,
  CareProfileFragment,
  CareProfileSenseOfFulfillmentEnum,
  CareProfileSocialSatisfactionEnum,
  CareProfileUseCaseEnum,
  ChatFragment,
  ClientRoleFragment,
  ClientRoleProviderEmotionalStateEnum,
  ClientRoleProviderFeelsSupportedEnum,
  ClientRoleRelationshipEnum,
  ContentConditionKindEnum,
  ExpertRoleRoleEnum,
  FocusAreaEnum,
  FolderFragment,
  GetActivitiesQuery,
  GetArticlesBasicQuery,
  GetArticlesSearchQuery,
  GetClientWithJourneysQuery,
  GetContentConditionsQuery,
  GetJourneyForMessagesQuery,
  GetJourneyWithPlanQuery,
  GetMeQuery,
  GoalFragment,
  GoalStatusEnum,
  GoalTemplateFragment,
  PackageGrantKindEnum,
  PdfTypeEnum,
  ResourceFragment,
  TaskFragment,
  TaskKindEnum,
  ToolEnum,
  UserTypeEnum,
  WebinarRecurMonthlyTypeEnum,
  WebinarRecurTypeEnum,
  WorksheetFragment
} from "@typing/Generated";
import { isNotNullOrUndefined } from "@utils/arrayUtils";
import { Colors } from "@utils/colorUtils";
import { IconName } from "@utils/iconUtils";
import { languageNameForCode } from "@utils/languageUtils";
import { joinListWithCommasAndAnd, stripText, truncate } from "@utils/stringUtils";
import { dateToIso8601, minutesToHoursAndMinutes, timeWithUnit } from "@utils/timeUtils";

type GetActivitiesJourney = NonNullable<
  NonNullable<NonNullable<GetActivitiesQuery["activities"]["edges"]>[number]>["node"]
>["journey"];

type GroupingContentCondition = GetContentConditionsQuery["contentConditions"][number];

export const sortByTimestamp = <T extends unknown>(
  models: T[],
  getDate: (t: T) => Date,
  direction: SortByTimestampDirectionEnum
) =>
  [...models].sort((a, b) => {
    if (!getDate(a) || !getDate(b)) {
      return 0;
    }
    switch (direction) {
      case SortByTimestampDirectionEnum.CHRONOLOGICAL:
        return getDate(a) > getDate(b) ? 1 : -1;
      case SortByTimestampDirectionEnum.REVERSE_CHRONOLOGICAL:
        return getDate(a) > getDate(b) ? -1 : 1;
      default:
        return getDate(a) > getDate(b) ? -1 : 1;
    }
  });

export const mostRecentDate = <T extends unknown>(models: T[], getDate: (t: T) => Date) => {
  if (models.length) {
    return models.reduce((a, b) => (getDate(a) > getDate(b) ? a : b));
  }
};

export const sortByStringField = <T extends unknown>(models: T[], getValue: (t: T) => string | number) =>
  [...models].sort((a, b) => {
    {
      const valueA = getValue(a);
      const valueB = getValue(b);
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    }
  });

export const isRichTextEmpty = (text: string) => {
  if (!text) return true;

  const regex = /(<([^>]+)>)/gi;
  // remove whitespace

  const hasText = text.replace(regex, "").trim();

  return !hasText;
};

export const groupBy = <T,>(list: T[], getKey: (item: T) => unknown) => {
  const map = new Map<unknown, T[]>();

  list.forEach(item => {
    const key = getKey(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map.values());
};

export const sortByTitle = <T extends { title: string }>(models: T[]) =>
  sortByStringField(models, model => model.title.toUpperCase());

export const sortByLabel = <T extends { label: string }>(models: T[]) =>
  sortByStringField(models, model => model.label.toUpperCase());

export const sortByPosition = <T extends { position: number }>(models: T[]) =>
  [...models].sort((a, b) => a.position - b.position);

export const modelWithUniqueIds = <T extends { id: string }>(models: T[]) => {
  const uniqueIds = new Set<string>();
  return models.filter(model => {
    if (uniqueIds.has(model.id)) {
      return false;
    }
    uniqueIds.add(model.id);
    return true;
  });
};

export const getFolderTitle = (folder: FolderFragment, intl: IntlShape) =>
  truncate(folder.title ?? intl.formatMessage({ id: `models.folderKind.${folder.kind}` }), 30);

type FileResource = {
  pdfType: PdfTypeEnum | null;
  title: string | null;
};

export const getFileResourceTitle = (fileResource: FileResource, intl: IntlShape) =>
  truncate(fileResource.title ?? intl.formatMessage({ id: `models.pdfType.${fileResource.pdfType}` }), 30);

const ordinalSuffixOf = (i: number | undefined | null) => {
  if (i === null || i === undefined) {
    return null;
  }
  const j = i % 10;
  const k = i % 100;
  const iInString = i.toString();

  if (j === 1 && k !== 11) {
    return iInString + "st";
  }
  if (j === 2 && k !== 12) {
    return iInString + "nd";
  }
  if (j === 3 && k !== 13) {
    return iInString + "rd";
  }
  return iInString + "th";
};

type ObjectWithPosition = {
  id: string;
  position: number;
};

export const moveArrayByIndex = (event: CustomEvent<ItemReorderEventDetail>, items: ObjectWithPosition[]) => {
  let newIndex = event.detail.to;
  let oldIndex = event.detail.from;
  if (newIndex === items.length) {
    newIndex--;
  }
  if (oldIndex === items.length) {
    oldIndex--;
  }

  const newArr: (ObjectWithPosition | undefined)[] = sortByPosition([...items]);
  if (newIndex >= newArr.length) {
    let k = newIndex - newArr.length + 1;
    while (k--) {
      newArr.push(undefined);
    }
  }
  newArr.splice(newIndex, 0, newArr.splice(oldIndex, 1)[0]);
  return newArr as ObjectWithPosition[];
};

type TaskConsultRecord = Extract<NonNullable<TaskFragment["record"]>, { __typename: "Consult" }>;
type TaskSignedDocumentRecord = Extract<NonNullable<TaskFragment["record"]>, { __typename: "SignedDocument" }>;
type TaskTaskRecord = Extract<NonNullable<TaskFragment["record"]>, { __typename: "Task" }>;
type TaskWorksheetRecord = {
  id: string;
  worksheetTitle: string;
};

export const getAllGoals = (journey: GetJourneyWithPlanQuery["journey"]) => [
  ...journey.goals,
  ...(journey.editModeGoals ?? [])
];

export const showDraft = (task: TaskFragment) => {
  if (task.goal && task.goal.status !== GoalStatusEnum.ACTIVE) {
    // if the goal is draft the task is considered draft
    if (task.goal.status === GoalStatusEnum.DRAFT) return true;

    const timeDifference = Math.abs(task.createdAt.getTime() - task.goal.createdAt.getTime());
    // if the goal is in edit mode and the task was added after the goal was created, it is considered draft
    if (timeDifference >= 1000) return true;
  }

  return false;
};

export const translationForTask = (
  task: TaskFragment,
  intl: IntlShape,
  getMyTimeWithTimezoneAbbreviation: (time: Date | undefined) => string
) => {
  if (task.title) {
    return task.title;
  }
  const consultRecord =
    TaskKindEnum.CONSULT_INTERPRETATION === task.kind ||
    TaskKindEnum.CONFIRM_CONSULT_INTERPRETATION === task.kind ||
    TaskKindEnum.SUBMIT_CONSULT_REPORT === task.kind ||
    TaskKindEnum.NINE_DAY_CHECK_IN === task.kind ||
    TaskKindEnum.NINETY_DAY_CHECK_IN === task.kind ||
    TaskKindEnum.SIXTY_DAY_CHECK_IN === task.kind ||
    TaskKindEnum.THIRTY_DAY_CHECK_IN === task.kind
      ? (task.record as TaskConsultRecord)
      : null;

  let consultLink = "";
  if (consultRecord?.position === 1) {
    consultLink = `/expert/journeys/${task.journey?.id ?? ""}/care-profile?docSection=sessions`;
  } else {
    consultLink = `/expert/journeys/${task.journey?.id ?? ""}/consults/${consultRecord?.id ?? ""}/consult-report
      ${consultRecord?.completed ? "" : "/edit"}`;
  }

  const taskRecord =
    task.kind === TaskKindEnum.REVIEW_RESOURCE || task.kind === TaskKindEnum.TASK_CANCELED || TaskKindEnum.TASK_REMINDER
      ? (task.record as TaskTaskRecord)
      : null;

  const signedDocumentRecord =
    task.kind === TaskKindEnum.SIGNED_DOCUMENT_COMPLETED || task.kind === TaskKindEnum.SIGNED_DOCUMENT_REVOKED
      ? (task.record as TaskSignedDocumentRecord)
      : null;

  const worksheetRecord =
    task.kind === TaskKindEnum.WORKSHEET_COMPLETED || task.kind === TaskKindEnum.WORKSHEET_STARTED
      ? (task.record as TaskWorksheetRecord)
      : null;

  return intl.formatMessage(
    { id: `models.tasks.kind.${task.kind ?? ""}` },
    {
      clientName: task.client?.fullName,
      goToCarePlan: (
        <InternalLink
          linkEventCreateVariables={{ journeyId: task.journey?.id }}
          to={`/expert/journeys/${task.journey?.id ?? ""}/care-plan`}
        >
          {taskRecord?.title}
        </InternalLink>
      ),
      goToReport: (
        <InternalLink linkEventCreateVariables={{ journeyId: task.journey?.id }} to={consultLink}>
          <FormattedMessage id="models.tasks.goToReport" />
        </InternalLink>
      ),
      language: languageNameForCode(consultRecord?.languageCode),
      languageServicesLink: (
        <ExternalLink
          className="bold"
          href="https://www.notion.so/withgrayce/Interpretation-Services-9abec6ed6d8846fcb4af89ce4a501194#eb4b23f163e74f83a6b0f91fec41445a"
          linkEventCreateVariables={{ journeyId: task.journey?.id }}
        >
          <FormattedMessage id="models.tasks.setupInterpretation" />
        </ExternalLink>
      ),
      name: signedDocumentRecord?.documentFor,
      reportType: intl.formatMessage({
        id: `models.tasks.reportType.${consultRecord?.position === 1 ? "initialConsultReport" : "consultReport"}`
      }),
      startDate: <DateStamp value={consultRecord?.startTime} />,
      startTime: getMyTimeWithTimezoneAbbreviation(consultRecord?.startTime),
      title: taskRecord?.title ?? worksheetRecord?.worksheetTitle,
      type:
        consultRecord?.position === 1
          ? intl.formatMessage({
              id: `models.tasks.initial`
            })
          : ordinalSuffixOf(consultRecord?.position),
      viewAccount: (
        <InternalLink
          linkEventCreateVariables={{ journeyId: task.journey?.id }}
          to={`/expert/journeys/${task.journey?.id ?? ""}/account`}
        >
          <FormattedMessage id="models.tasks.viewAccount" />
        </InternalLink>
      )
    }
  );
};

type GetCareProfileNameArgs = {
  defaultTx?: string;
  intl: IntlShape;
  journey:
    | {
        careProfile: Pick<CareProfileFragment, "useCase" | "name">;
        ownerRole: Pick<ClientRoleFragment, "relationship"> | null;
      }
    | null
    | undefined;
};

export const getCareProfileName = ({ defaultTx, intl, journey }: GetCareProfileNameArgs) => {
  const defaultText = intl.formatMessage({ id: defaultTx ?? "dictionary.unknown" });
  if (!journey) return defaultText;

  if (
    journey.careProfile.useCase === CareProfileUseCaseEnum.SELF ||
    journey.ownerRole?.relationship?.relationshipOption === ClientRoleRelationshipEnum.MYSELF
  ) {
    return intl.formatMessage({ id: "dictionary.self" });
  }

  return journey.careProfile.name || defaultText;
};

type ThingWithExpertRole = {
  role: ExpertRoleRoleEnum;
};

export function primaryExpertRole<T extends ThingWithExpertRole>(expertRoles?: T[] | null) {
  return expertRoles?.find(role => role.role === ExpertRoleRoleEnum.PRIMARY);
}

export function expertRolesWithoutBackups<T extends ThingWithExpertRole>(expertRoles?: T[] | null) {
  return expertRoles?.filter(role => role.role !== ExpertRoleRoleEnum.BACKUP);
}

export const getChatUsersByFirstName = (
  me: GetMeQuery["me"] | undefined,
  chat: ChatFragment,
  journey: GetJourneyForMessagesQuery["journey"]
) => {
  const experts =
    expertRolesWithoutBackups(journey.expertRoles)
      ?.filter(er => er.expert.id !== me?.id)
      .map(er => er.expert.firstName) ?? [];
  const clients = chat.clients.filter(client => client.id !== me?.id).map(client => client.firstName);

  return [...experts, ...clients];
};

export const getChatUsers = (
  me: GetMeQuery["me"] | undefined,
  chat: ChatFragment,
  journey: GetJourneyForMessagesQuery["journey"]
) => {
  const colorOrder: Colors[] = ["secondary-100", "secondary", "primary-300", "primary"];
  const experts =
    expertRolesWithoutBackups(journey.expertRoles)
      ?.filter(er => er.expert.id !== me?.id)
      .map(er => ({
        avatarProps: { expert: er.expert },
        id: er.expert.id,
        name: er.expert.firstName,
        userType: UserTypeEnum.EXPERT
      })) ?? [];
  const clients = chat.clients
    .filter(client => client.id !== me?.id)
    .map((client, index) => ({
      avatarProps: {
        color: colorOrder[index % colorOrder.length],
        firstName: client.firstName
      },
      id: client.id,
      name: client.firstName,
      userType: UserTypeEnum.CLIENT
    }));

  return [...experts, ...clients];
};

export const colorForStatus = (status: JourneyStatusEnum) => {
  if (status === JourneyStatusEnum.ARCHIVED) {
    return "gray-300";
  }
  if (status === JourneyStatusEnum.UPDATED) {
    return "primary-300";
  }
  if (status === JourneyStatusEnum.CANCELED) {
    return "danger";
  }
  if (status === JourneyStatusEnum.COMPLETED) {
    return "success";
  }
  if (status === JourneyStatusEnum.DRAFT || status === JourneyStatusEnum.UNPUBLISHED_ITEMS) {
    return "danger-300";
  }
  if (status === JourneyStatusEnum.IN_PROGRESS) {
    return "danger-500";
  }
  if (status === JourneyStatusEnum.VIEWED) {
    return "secondary-500";
  }
  return "primary-500";
};

export const iconForStatus = (status: JourneyStatusEnum) => {
  if (status === JourneyStatusEnum.ARCHIVED) {
    return "boxArchive";
  }
  if (status === JourneyStatusEnum.DRAFT || status === JourneyStatusEnum.UNPUBLISHED_ITEMS) {
    return "loader";
  }
  if (status === JourneyStatusEnum.UPDATED) {
    return "circleExclamation";
  }
  if (status === JourneyStatusEnum.CANCELED) {
    return "times";
  }
  if (status === JourneyStatusEnum.COMPLETED) {
    return "checkmark";
  }
  if (status === JourneyStatusEnum.IN_PROGRESS) {
    return "pencil";
  }
  if (status === JourneyStatusEnum.VIEWED) {
    return "eye";
  }
  return "star";
};

type ToolsGroupedProps = {
  carePlanActionItems?: GoalFragment["carePlanActionItems"];
  carePlanTaskTemplates?: GoalTemplateFragment["carePlanTaskTemplates"];
  goals?: GoalFragment[];
  journey: GetJourneyWithPlanQuery["journey"];
};

type Tool = {
  status: JourneyStatusEnum;
  tool?: ToolEnum;
  worksheet?: GetJourneyWithPlanQuery["journey"]["worksheets"][0];
  worksheetTemplate?: GoalTemplateFragment["worksheetTemplates"][0];
};

type GroupedTools = {
  completed: Tool[];
  inProgress: Tool[];
  new: Tool[];
};

const expertModifiableFieldsFromActionItem = (item: CarePlanActionItemFragment) => ({
  archived: item.archived,
  assigmentIds: item.assignments?.map(assignment => assignment.id),
  completed: item.completed,
  title: stripText(item.title).trim()
});

const expertModifiableFieldsFromWorksheet = (worksheet: WorksheetFragment) => ({
  completed: worksheet.completed,
  description: stripText(worksheet.description).trim(),
  title: worksheet.title,
  worksheetItems: sortByPosition(worksheet.worksheetItems)?.map(item => ({
    acceptsCheckbox: item.acceptsCheckbox,
    acceptsDetails: item.acceptsDetails,
    completed: item.completed,
    details: item.details,
    title: item.title
  }))
});

const expertModifiableFieldsFromResource = (resource: ResourceFragment) => ({
  cost: resource.cost,
  description: stripText(resource.description).trim(),
  featureValues: resource.featureValues?.map(fv => ({
    booleanValue: fv.booleanValue,
    textValue: fv.textValue
  })),
  linkTitle: resource.linkTitle,
  linkUrl: resource.linkUrl,
  notes: resource.notes,
  phoneNumber: resource.phoneNumber,
  title: resource.title,
  useful: resource.useful
});

const expertModifiableFieldsFromTask = (task: TaskFragment) => ({
  archived: task.archived,
  canceled: task.canceled,
  category: {
    option: task.category?.categoryOption,
    other: task.category?.other
  },
  completed: task.completed,
  dueTime: task.dueTime,
  expert: task.expert.id,
  kind: task.kind,
  resourceFeatures: task.resourceFeatures?.map(f => ({
    kind: f.kind,
    name: f.name
  })),
  title: task.title
});

export const carePlanActionItemHasChanges = (
  actionItem: CarePlanActionItemFragment,
  originalGoal?: GetJourneyWithPlanQuery["journey"]["goals"][0]
) =>
  originalGoal?.carePlanActionItems.every(
    ai => !deepEqual(expertModifiableFieldsFromActionItem(ai), expertModifiableFieldsFromActionItem(actionItem))
  );

export const resourceHasChanges = (
  resource: ResourceFragment,
  originalGoal?: GetJourneyWithPlanQuery["journey"]["goals"][0]
) =>
  originalGoal?.tasks
    .flatMap(t => t.resources)
    .every(r => !deepEqual(expertModifiableFieldsFromResource(r), expertModifiableFieldsFromResource(resource)));

export const worksheetHasChanges = (
  worksheet: WorksheetFragment,
  originalGoal?: GetJourneyWithPlanQuery["journey"]["goals"][0]
) =>
  originalGoal?.worksheets.every(
    w => !deepEqual(expertModifiableFieldsFromWorksheet(w), expertModifiableFieldsFromWorksheet(worksheet))
  );

export const taskHasChanges = (task: TaskFragment, originalGoal?: GetJourneyWithPlanQuery["journey"]["goals"][0]) =>
  originalGoal?.tasks.every(t => !deepEqual(expertModifiableFieldsFromTask(t), expertModifiableFieldsFromTask(task)));

type GoalArticle = Extract<GoalFragment["goalItems"][number]["resource"], { __typename: "Article" }>;
type GoalEmployerBenefit = Extract<GoalFragment["goalItems"][number]["resource"], { __typename: "EmployerBenefit" }>;

export const articlesForGoal = (goal: GoalFragment | undefined | null): GoalArticle[] => {
  if (goal) {
    return goal.goalItems
      .filter(item => item.resource?.__typename === "Article")
      .map(item => item.resource) as GoalArticle[];
  }
  return [] as GoalArticle[];
};

export const employerBenefitsForGoal = (goal: GoalFragment | undefined | null): GoalEmployerBenefit[] => {
  if (goal) {
    return goal.goalItems
      .filter(item => item.resource?.__typename === "EmployerBenefit")
      .map(item => item.resource) as GoalEmployerBenefit[];
  }
  return [] as GoalEmployerBenefit[];
};

export const toolsForGoal = (goal: GoalFragment | undefined | null): ToolEnum[] => {
  if (goal) {
    return goal.goalItems.filter(item => item.tool).map(item => item.tool) as ToolEnum[];
  }
  return [] as ToolEnum[];
};

const groupTools = ({ goals, journey }: ToolsGroupedProps) => {
  const groups: GroupedTools = {
    completed: [],
    inProgress: [],
    new: []
  };

  journey?.worksheets.forEach(worksheet => {
    if (worksheet.completed) {
      groups.completed.push({ status: JourneyStatusEnum.COMPLETED, worksheet });
    } else if (worksheet.worksheetItems.some(item => item.completed || (item.details && item.details.length > 0))) {
      groups.inProgress.push({ status: JourneyStatusEnum.IN_PROGRESS, worksheet });
    } else {
      groups.new.push({ status: JourneyStatusEnum.NEW, worksheet });
    }
  });

  if (goals?.some(goal => toolsForGoal(goal).includes(ToolEnum.COMPLETE_LEGAL_DOCUMENT_TRACKER))) {
    groups.new.push({
      status: JourneyStatusEnum.NEW,
      tool: ToolEnum.COMPLETE_LEGAL_DOCUMENT_TRACKER
    });
  }

  if (goals?.some(goal => toolsForGoal(goal).includes(ToolEnum.COMPLETE_MEDICATION_TRACKER))) {
    groups.new.push({ status: JourneyStatusEnum.NEW, tool: ToolEnum.COMPLETE_MEDICATION_TRACKER });
  }

  if (goals?.some(goal => toolsForGoal(goal).includes(ToolEnum.COMPLETE_PROVIDER_LIST))) {
    groups.new.push({ status: JourneyStatusEnum.NEW, tool: ToolEnum.COMPLETE_PROVIDER_LIST });
  }

  if (goals?.some(goal => toolsForGoal(goal).includes(ToolEnum.COMPLETE_CONSENT_FORM))) {
    groups.new.push({
      status: JourneyStatusEnum.NEW,
      tool: ToolEnum.COMPLETE_CONSENT_FORM
    });
  }

  return groups;
};

export const getOrderedToolsForGoal = ({
  goal,
  journey
}: {
  goal?: GetJourneyWithPlanQuery["journey"]["goals"][number] | null;
  journey: GetJourneyWithPlanQuery["journey"];
}) => {
  if (!goal) return [];

  const groups = groupTools({
    carePlanActionItems: goal.carePlanActionItems,
    goals: [goal],
    journey
  });

  const toolGoalItems = goal.goalItems.map(gi => gi.tool).filter(isNotNullOrUndefined);

  return [...groups.new, ...groups.inProgress, ...groups.completed].filter(
    tool => tool.worksheet?.goal?.id === goal.id || (tool.tool && toolGoalItems.includes(tool.tool))
  );
};

export const tagPropsForFocusArea = (focusArea: FocusAreaEnum, intl: IntlShape): TagProps => {
  const text = intl.formatMessage({ id: `models.focusArea.${focusArea}` });
  if (focusArea === FocusAreaEnum.FUNCTIONAL) {
    return { color: "primary-500", text };
  } else if (focusArea === FocusAreaEnum.FINANCIAL) {
    return { color: "success", text };
  } else if (focusArea === FocusAreaEnum.LEGAL) {
    return { color: "gray", text };
  } else if (focusArea === FocusAreaEnum.MEDICAL) {
    return { color: "secondary-500", text };
  } else if (focusArea === FocusAreaEnum.SOCIAL) {
    return { color: "eggplant", text };
  } else if (focusArea === FocusAreaEnum.UNDER_EIGHTEEN) {
    return { color: "sky", text };
  }
  return { color: "orange", text };
};

type ModelDayStartTime<T extends unknown> = {
  date: number;
  models: T[];
  month: number;
  year: number;
};

export const groupByTimestampDay = <T extends unknown>(
  models: T[],
  getDate: (t: T) => Date,
  intl: IntlShape,
  reverse = false
) => {
  const days: ModelDayStartTime<T>[] = [];
  models.forEach(model => {
    const dateParts = intl.formatDateToParts(getDate(model));
    const dateString = dateParts.find(item => item.type === "day")?.value;
    const monthString = dateParts.find(item => item.type === "month")?.value;
    const yearString = dateParts.find(item => item.type === "year")?.value;
    if (dateString && monthString && yearString) {
      const date = parseInt(dateString);
      const month = parseInt(monthString) - 1;
      const year = parseInt(yearString);
      const existingDay = days.find(day => day.date === date && day.month === month && day.year === year);
      if (existingDay) {
        existingDay.models.push(model);
      } else {
        const day = {
          date,
          models: [model],
          month,
          year
        };
        days.push(day);
      }
    }
  });
  days.forEach(day => {
    day.models = day.models.sort((a, b) =>
      reverse ? (getDate(a) > getDate(b) ? 1 : -1) : getDate(a) > getDate(b) ? -1 : 1
    );
  });
  const sorted = days.sort((a, b) => {
    if (a.year !== b.year) {
      return reverse ? (a.year > b.year ? 1 : -1) : a.year > b.year ? -1 : 1;
    }
    if (a.month !== b.month) {
      return reverse ? (a.month > b.month ? 1 : -1) : a.month > b.month ? -1 : 1;
    }
    return reverse ? (a.date > b.date ? 1 : -1) : a.date > b.date ? -1 : 1;
  });

  return sorted;
};

export const orderedTasks = (clientVisibleTasks: TaskFragment[]) => {
  const sortedTasks = [...clientVisibleTasks].sort((a, b) => {
    const aMaxDate = Math.max(a.updatedAt, ...a.resources.map(resource => resource.updatedAt));
    const bMaxDate = Math.max(b.updatedAt, ...b.resources.map(resource => resource.updatedAt));

    return bMaxDate - aMaxDate;
  });

  return sortedTasks;
};

type ContentConditionGroups = Record<
  string,
  {
    kinds: Record<
      string,
      {
        answers: Record<string, any[]>;
      }
    >;
  }
>;

type ModelActivitiesJourney = {
  journey: GetActivitiesJourney | null;
  journeyId: string | null;
  models: ActivityType[];
};

export const groupActivitiesByJourneyId = (activities: ActivityType[]) => {
  const journeys: ModelActivitiesJourney[] = [];
  activities.forEach(activity => {
    if (activity.journey && activity.journeyId) {
      const existingJourney = journeys.find(journey => journey.journeyId === activity.journeyId);

      if (existingJourney) {
        existingJourney.models.push(activity);
      } else {
        const journey = {
          journey: activity.journey,
          journeyId: activity.journeyId,
          models: [activity]
        };
        journeys.push(journey);
      }
    }
  });
  journeys.forEach(journey => {
    journey.models = journey.models.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  });

  return journeys.sort((a, b) => (a.models[0].createdAt > b.models[0].createdAt ? -1 : 1));
};

export const calculateDatesFromTimePeriod = (
  timePeriod: TimePeriodEnum | null | undefined,
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  intl: IntlShape
) => {
  const finalStartDate = new Date();

  if (timePeriod === TimePeriodEnum.CUSTOM) {
    return { endDate, startDate };
  }
  if (timePeriod === TimePeriodEnum.ALL_TIME) {
    return { endDate: null, startDate: null };
  }
  if (timePeriod === TimePeriodEnum.SEVEN_DAYS) {
    const pastDate = finalStartDate.getDate() - 7;
    finalStartDate.setDate(pastDate);
  } else if (timePeriod === TimePeriodEnum.THIRTY_DAYS) {
    const pastDate = finalStartDate.getDate() - 30;
    finalStartDate.setDate(pastDate);
  } else if (timePeriod === TimePeriodEnum.SIXTY_DAYS) {
    const pastDate = finalStartDate.getDate() - 60;
    finalStartDate.setDate(pastDate);
  } else if (timePeriod === TimePeriodEnum.NINETY_DAYS) {
    const pastDate = finalStartDate.getDate() - 90;
    finalStartDate.setDate(pastDate);
  }

  return {
    endDate: dateToIso8601(new Date(), intl).split("T")[0],
    startDate: dateToIso8601(finalStartDate, intl).split("T")[0]
  };
};

type Article = NonNullable<NonNullable<NonNullable<GetArticlesBasicQuery["articles"]["edges"]>[number]>["node"]>;

type GroupedArticles = Record<FocusAreaEnum, Article[]>;

export const groupArticlesByFocusArea = <T extends Article>(articles: T[]) => {
  const groupedArticles: GroupedArticles = {
    financial: [],
    functional: [],
    legal: [],
    medical: [],
    social: [],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    under_eighteen: [],
    wellbeing: []
  };

  articles.forEach(article => {
    article.focusAreas.forEach(focusArea => {
      groupedArticles[focusArea].push(article);
    });
  });

  return groupedArticles;
};

type ModelWithFocusArea = {
  focusAreas: FocusAreaEnum[];
  id: string;
};

export const groupByFocusArea = <T extends ModelWithFocusArea>(models: T[]) => {
  const results: Partial<Record<FocusAreaEnum, T[]>> = {};

  [
    FocusAreaEnum.FUNCTIONAL,
    FocusAreaEnum.FINANCIAL,
    FocusAreaEnum.LEGAL,
    FocusAreaEnum.MEDICAL,
    FocusAreaEnum.SOCIAL,
    FocusAreaEnum.UNDER_EIGHTEEN,
    FocusAreaEnum.WELLBEING
  ].forEach(focusArea => (results[focusArea] = models.filter(model => model.focusAreas.includes(focusArea))));

  return results;
};

export const groupContentConditions = (contentConditions: GroupingContentCondition[], intl: IntlShape) => {
  const groupedConditions: ContentConditionGroups = {};
  contentConditions.forEach(condition => {
    let careProfileFieldKind = null;
    if (
      condition.careProfileField &&
      Object.values(PersonalizeExperienceFieldEnum)
        .map(item => item.toString())
        .includes(condition.careProfileField.toString())
    ) {
      careProfileFieldKind = "pme";
    } else if (
      condition.careProfileField &&
      Object.values(InitialConsultFieldEnum)
        .map(item => item.toString())
        .includes(condition.careProfileField.toString())
    ) {
      careProfileFieldKind = "initialConsultReport";
    }

    const source = intl.formatMessage({
      id: `models.contentCondition.source.${careProfileFieldKind ?? condition.kind}`
    });

    groupedConditions[source] = groupedConditions[source] || { kinds: {} };
    if (condition.kind === ContentConditionKindEnum.CARE_PROFILE_FIELD_VALUE) {
      if (condition.careProfileField && condition.careProfileValue) {
        const field = condition.careProfileField;
        const value = condition.careProfileValue;
        groupedConditions[source].kinds[field] = groupedConditions[source].kinds[field] || {
          answers: {}
        };
        groupedConditions[source].kinds[field].answers[value] =
          groupedConditions[source].kinds[field].answers[value] || [];
        groupedConditions[source].kinds[field].answers[value].push(condition.content);
      }
    } else {
      const kind = intl.formatMessage({ id: `models.contentCondition.kind.${condition.kind}` });
      let answer: string | null = "";
      switch (condition.kind) {
        case ContentConditionKindEnum.RELATIONSHIP_SELECTED:
          answer = intl.formatMessage({ id: `models.clientRole.relationship.${condition.relationship ?? ""}` });
          break;
        default:
          break;
      }
      if (answer !== null) {
        groupedConditions[source].kinds[kind] = groupedConditions[source].kinds[kind] || {
          answers: {}
        };
        groupedConditions[source].kinds[kind].answers[answer] =
          groupedConditions[source].kinds[kind].answers[answer] || [];
        groupedConditions[source].kinds[kind].answers[answer].push(condition.content);
      }
    }
  });
  return groupedConditions;
};

export const contentConditionKindsForSources = (sources: (ContentConditionSourceEnum | null | undefined)[]) => {
  const result: ContentConditionKindEnum[] = [];
  sources.forEach(source => {
    switch (source) {
      case ContentConditionSourceEnum.ALWAYS:
        result.push(ContentConditionKindEnum.ALWAYS);
        break;
      case ContentConditionSourceEnum.CARE_PROFILE:
        result.push(ContentConditionKindEnum.PROVIDER_EMOTIONAL_STATE_SELECTED);
        result.push(ContentConditionKindEnum.PROVIDER_FEELS_SUPPORTED_SELECTED);
        result.push(ContentConditionKindEnum.RELATIONSHIP_SELECTED);
        result.push(ContentConditionKindEnum.RESIDE_IN_DIFFERENT_COUNTRIES);
        result.push(ContentConditionKindEnum.CARE_PROFILE_FIELD_VALUE);
        break;
      case ContentConditionSourceEnum.CONSULTS:
        result.push(ContentConditionKindEnum.NO_CONSULT_SCHEDULED);
        break;
      case ContentConditionSourceEnum.LEGAL_DOCUMENTS:
        result.push(ContentConditionKindEnum.DPOA_FINANCES_INCOMPLETE);
        result.push(ContentConditionKindEnum.DPOA_HEALTH_CARE_INCOMPLETE);
        result.push(ContentConditionKindEnum.LIVING_WILL_INCOMPLETE);
        result.push(ContentConditionKindEnum.TRUST_INCOMPLETE);
        result.push(ContentConditionKindEnum.WILL_INCOMPLETE);
        break;
      case ContentConditionSourceEnum.MEDICATION_TRACKER:
        result.push(ContentConditionKindEnum.MORE_THAN_THREE_MEDICATIONS);
        break;
      case ContentConditionSourceEnum.PROVIDER_LIST:
        result.push(ContentConditionKindEnum.NO_PRIMARY_CARE_PHYSICIAN);
        break;
      case ContentConditionSourceEnum.JOURNEY:
        result.push(ContentConditionKindEnum.STAGE_SELECTED);
        break;
      default:
        break;
    }
  });
  return result;
};

export const calculateStartTimeFromEnum = (value: StartTimeValuesEnum | null | undefined) => {
  const defaultDate = new Date();
  return value === StartTimeValuesEnum.PAST_SEVEN_DAYS
    ? new Date(defaultDate.getTime() - oneDayInMs * 7)
    : value === StartTimeValuesEnum.PAST_THIRTY_DAYS
      ? new Date(defaultDate.getTime() - oneDayInMs * 30)
      : value === StartTimeValuesEnum.PAST_THREE_DAYS
        ? new Date(defaultDate.getTime() - oneDayInMs * 3)
        : null;
};

export const calculateActivityKindsFromEnum = (
  activityKind: ActivityKindEnum | OtherActivityKindsEnum | null | undefined
) => {
  let activityKinds: ActivityKindEnum[] | null = [];
  if (activityKind === OtherActivityKindsEnum.ASSESSMENT_COMPLETED) {
    activityKinds.push(
      ActivityKindEnum.SAFETY_QUIZ_COMPLETED,
      ActivityKindEnum.LEGAL_TRACKER_COMPLETED,
      ActivityKindEnum.PROVIDER_TRACKER_COMPLETED,
      ActivityKindEnum.MEDICATION_TRACKER_COMPLETED
    );
  } else if (activityKind === OtherActivityKindsEnum.CONTENT_VIEWED) {
    activityKinds.push(ActivityKindEnum.ARTICLE_OPENED, ActivityKindEnum.ARTICLE_VIDEO_PLAYED);
  } else if (!activityKind || activityKind === OtherActivityKindsEnum.ALL) {
    activityKinds = null;
  } else {
    activityKinds = [activityKind];
  }
  return activityKinds;
};

export const filterActivityGroupWithOneArticle = (group: ModelActivitiesJourney | ModelDayStartTime<ActivityType>) => {
  const newGroup = { ...group };
  const firstActivityArticleViewed = group.models.find(activity => activity.kind === ActivityKindEnum.ARTICLE_OPENED);

  newGroup.models = group.models.filter(
    activity => activity.kind !== ActivityKindEnum.ARTICLE_OPENED || activity.id === firstActivityArticleViewed?.id
  );
  return newGroup;
};

const packageGrantDescription = (args: {
  hasCarePlanning: boolean;
  hasUnlimitedMinutes: boolean;
  intl: IntlShape;
  minutes: number | undefined;
}) => {
  if (args.hasUnlimitedMinutes) {
    return args.intl.formatMessage({ id: "dictionary.unlimited" });
  }

  const cpPart = args.hasCarePlanning ? args.intl.formatMessage({ id: "pages.client.account.cpPlus" }) : "";
  const transition = args.hasCarePlanning ? ` ${args.intl.formatMessage({ id: "dictionary.punctuation.plus" })} ` : "";
  const hourPart = minutesToHoursAndMinutes({ dropZeroMinutes: true, minutes: args.minutes });
  const hours = args.intl.formatMessage({ id: "dictionary.hoursLower" });

  if (args.hasCarePlanning && args.minutes === 0) {
    return cpPart;
  }
  return `${cpPart}${transition}${hourPart} ${hours}`;
};

export const packageGrantOverview = (args: {
  hasCarePlanning: boolean;
  hasUnlimitedMinutes: boolean;
  intl: IntlShape;
  minutes: number | undefined;
}) => {
  const packagePart = args.intl.formatMessage({ id: "dictionary.packageLower" });

  if (args.hasUnlimitedMinutes) {
    return `${args.intl.formatMessage({ id: "dictionary.unlimited" })} ${packagePart}`;
  }

  const cpPart = args.hasCarePlanning ? args.intl.formatMessage({ id: "dictionary.carePlan" }) : "";
  const transition = args.hasCarePlanning ? ` ${args.intl.formatMessage({ id: "dictionary.punctuation.plus" })} ` : "";

  if (args.hasCarePlanning && args.minutes === 0) {
    return `${cpPart} ${packagePart}`;
  }

  return `${cpPart}${transition}${timeWithUnit({ intl: args.intl, minutes: args.minutes, singular: true })} ${packagePart}`;
};

export const packageGrantRemaining = (args: {
  expiresAt: string | Date;
  hasUnlimitedMinutes: boolean | undefined;
  intl: IntlShape;
  minutes: number | undefined;
  minutesRemaining: number;
}) => {
  const expiresText = () => (
    <>
      {args.intl.formatMessage({ id: "dictionary.punctuation.comma" })}
      <Nbsp />
      {args.intl.formatMessage({ id: "dictionary.expiring" }).toLowerCase()}
      <Nbsp />
      <DateStamp value={args.expiresAt} />
    </>
  );

  if (args.hasUnlimitedMinutes || args.minutes == null) {
    return (
      <>
        {args.intl.formatMessage({ id: "dictionary.expiring" })}
        <Nbsp />
        <DateStamp value={args.expiresAt} />
      </>
    );
  }
  return (
    <>
      {timeWithUnit({ intl: args.intl, minutes: args.minutesRemaining, singular: false })}
      <Nbsp />
      {args.intl.formatMessage({ id: "dictionary.remainingLower" })}
      {args.minutesRemaining > 0 && expiresText()}
    </>
  );
};

export const clientPackageSummary = (args: { clientWithGrants: ClientWithGrants | undefined; intl: IntlShape }) => {
  const hasUnlimited = args.clientWithGrants?.eligiblePackageGrants.some(
    grant => !grant.expired && grant.hasUnlimitedMinutes
  );

  const hasCarePlanning = args.clientWithGrants?.eligiblePackageGrants.some(
    grant => !grant.expired && grant.hasCarePlanning && !grant.carePlanningCompleted
  );

  if (hasUnlimited) {
    return args.intl.formatMessage({ id: "dictionary.unlimited" });
  }

  const cpPart = hasCarePlanning ? args.intl.formatMessage({ id: "dictionary.carePlan" }) : "";
  const transition = hasCarePlanning ? ` ${args.intl.formatMessage({ id: "dictionary.punctuation.plus" })} ` : "";

  if (hasCarePlanning && args.clientWithGrants?.timeBalance === 0) {
    return cpPart;
  }

  return `${cpPart}${transition}${timeWithUnit({ intl: args.intl, minutes: args.clientWithGrants?.timeBalance, singular: false })}`;
};

type ClientWithGrants = {
  eligiblePackageGrants: {
    carePlanningCompleted: boolean;
    carePlanningJourneyId: string | null;
    expired: boolean;
    hasCarePlanning: boolean;
    hasUnlimitedMinutes: boolean;
  }[];
  timeBalance: number;
};

type JourneyForGrants = {
  id: string;
};

export const currentPlanBalance = (
  clientWithGrants: ClientWithGrants | undefined,
  intl: IntlShape,
  journey?: JourneyForGrants
) => {
  if (clientWithGrants) {
    const packageGrants = clientWithGrants.eligiblePackageGrants;
    if (packageGrants) {
      const hasCarePlanning = packageGrants.some(
        grant =>
          !grant.expired &&
          grant.hasCarePlanning &&
          !grant.carePlanningCompleted &&
          (!grant.carePlanningJourneyId || !journey || grant.carePlanningJourneyId === journey?.id)
      );
      const hasUnlimitedMinutes = packageGrants.some(grant => !grant.expired && grant.hasUnlimitedMinutes);
      const timeBalance = clientWithGrants.timeBalance;

      return packageGrantDescription({ hasCarePlanning, hasUnlimitedMinutes, intl, minutes: timeBalance });
    }
  }
};

// I've addressed this to work with renewals, but it still feels like a mess because it's not entirely
// clear what it means to have a basic package with renewals, where you might have gotten multiple packages
// over the course of time. Since we only use this to determine if we should allow someone to do care plan
// lite, I'm assuming it will only be checked right after signup, so the test is for whether their initial
// package grant represents a basic package.

type JourneyForBasic = {
  id: string;
  owner: {
    eligiblePackageGrants: {
      createdAt: Date;
      hasCarePlanning: boolean;
      kind: PackageGrantKindEnum;
      minutes: number;
    }[];
  };
};
export const hasBasicPackage = (journey: JourneyForBasic | undefined) => {
  const packageGrants = journey?.owner.eligiblePackageGrants;
  const activeAllocations = packageGrants?.filter(grant => grant.kind === PackageGrantKindEnum.EMPLOYER_ALLOCATION);
  const firstActiveAllocation = sortByTimestamp(
    activeAllocations ?? [],
    grant => grant.createdAt,
    SortByTimestampDirectionEnum.CHRONOLOGICAL
  )[0];
  if (firstActiveAllocation) {
    return !!(firstActiveAllocation.hasCarePlanning && !firstActiveAllocation.minutes);
  }
  return true;
};

type ExpertWithCoverage = {
  coversAsia: boolean;
  coversEurope: boolean;
  coversUsEast: boolean;
  coversUsWest: boolean;
};

export const expertCoverageTypes = (expert: ExpertWithCoverage, intl: IntlShape) => {
  const expertCoverageValues = [];
  if (expert.coversAsia) {
    expertCoverageValues.push(intl.formatMessage({ id: "dictionary.asia" }));
  }
  if (expert.coversEurope) {
    expertCoverageValues.push(intl.formatMessage({ id: "dictionary.europe" }));
  }
  if (expert.coversUsEast) {
    expertCoverageValues.push(intl.formatMessage({ id: "models.expert.coversUsEast" }));
  }
  if (expert.coversUsWest) {
    expertCoverageValues.push(intl.formatMessage({ id: "models.expert.coversUsWest" }));
  }

  return joinListWithCommasAndAnd(expertCoverageValues, intl);
};

type ArticleBasicType = Omit<
  Omit<NonNullable<NonNullable<GetArticlesSearchQuery["articlesSearch"]>[number]>, "highlight">,
  "__typename"
>;
export const articleHasVideo = (article: ArticleBasicType) =>
  article.contentType === ArticleContentTypeEnum.VIDEO ||
  article.contentType === ArticleContentTypeEnum.MY_STORY ||
  (article.contentType === ArticleContentTypeEnum.WEBINAR && article.webinarCompleted === true);

export const providerFeelsSupportedIcon = (providerFeelsSupported: ClientRoleProviderFeelsSupportedEnum): IconName => {
  switch (providerFeelsSupported) {
    case ClientRoleProviderFeelsSupportedEnum.ITS_COMPLICATED:
      return "commentExclamation";
    case ClientRoleProviderFeelsSupportedEnum.NO:
      return "thumbsDown";
    case ClientRoleProviderFeelsSupportedEnum.YES:
      return "thumbsUp";
  }
};

export const providerEmotionalStateIcon = (providerEmotionalState: ClientRoleProviderEmotionalStateEnum): IconName => {
  switch (providerEmotionalState) {
    case ClientRoleProviderEmotionalStateEnum.NEGATIVE:
      return "faceFrownOpen";
    case ClientRoleProviderEmotionalStateEnum.NEUTRAL:
      return "faceMeh";
    case ClientRoleProviderEmotionalStateEnum.POSITIVE:
      return "faceSmile";
    case ClientRoleProviderEmotionalStateEnum.VERY_NEGATIVE:
      return "faceFrown";
    case ClientRoleProviderEmotionalStateEnum.VERY_POSITIVE:
      return "faceLaugh";
  }
};

export const senseOfFulfillmentIcon = (value: CareProfileSenseOfFulfillmentEnum): IconName | null => {
  switch (value) {
    case CareProfileSenseOfFulfillmentEnum.NOT_AT_ALL:
      return "faceFrown";
    case CareProfileSenseOfFulfillmentEnum.LEVEL_2:
      return "faceFrownOpen";
    case CareProfileSenseOfFulfillmentEnum.NEUTRAL:
      return "faceMeh";
    case CareProfileSenseOfFulfillmentEnum.LEVEL_4:
      return "faceSmile";
    case CareProfileSenseOfFulfillmentEnum.EXTREMELY:
      return "faceLaugh";
    case CareProfileSenseOfFulfillmentEnum.NOT_ADDRESSED:
      return null;
  }
};

export const socialSatisfactionIcon = (value: CareProfileSocialSatisfactionEnum): IconName | null => {
  switch (value) {
    case CareProfileSocialSatisfactionEnum.NOT_AT_ALL:
      return "faceFrown";
    case CareProfileSocialSatisfactionEnum.LEVEL_2:
      return "faceFrownOpen";
    case CareProfileSocialSatisfactionEnum.NEUTRAL:
      return "faceMeh";
    case CareProfileSocialSatisfactionEnum.LEVEL_4:
      return "faceSmile";
    case CareProfileSocialSatisfactionEnum.EXTREMELY:
      return "faceLaugh";
    case CareProfileSocialSatisfactionEnum.NOT_ADDRESSED:
      return null;
  }
};

type Message = {
  author:
    | {
        __typename: "Admin" | "Client" | "Expert";
        firstName: string;
      }
    | {
        __typename: "ClientDeletion";
      };
};

export const messagePresentedName = (message: Message, intl: IntlShape) => {
  if (message.author.__typename === "ClientDeletion") {
    return intl.formatMessage({ id: "models.clientDeletion.fullName" });
  } else {
    return message.author.firstName;
  }
};

type ClientForClientCanAddJourneys = Pick<
  GetClientWithJourneysQuery["client"],
  "eligiblePackageGrants" | "hasUnlimitedMinutes" | "timeBalance"
>;

export const clientCanAddJourneys = (client: ClientForClientCanAddJourneys) =>
  client.hasUnlimitedMinutes ||
  client.timeBalance > 0 ||
  client.eligiblePackageGrants.some(grant => grant.hasCarePlanning && !grant.carePlanningJourneyId);

type RecurringArticle = Pick<
  ArticleFragment,
  | "webinarOccurrenceRemainingSiblings"
  | "webinarRecurMonthlyDay"
  | "webinarRecurMonthlyType"
  | "webinarRecurMonthlyWeek"
  | "webinarRecurMonthlyWeekday"
  | "webinarRecurRepeatInterval"
  | "webinarRecurType"
  | "webinarRecurWeeklyDays"
  | "webinarRegistration"
  | "webinarStartTime"
>;

export const webinarRecurrenceDescription = (article: RecurringArticle, intl: IntlShape) => {
  const parts: string[] = [];

  parts.push(intl.formatMessage({ id: "models.article.webinarRecurDescription.repeats" }));

  if (article.webinarRecurType === WebinarRecurTypeEnum.DAILY) {
    if (article.webinarRecurRepeatInterval === 1) {
      parts.push(intl.formatMessage({ id: "models.article.webinarRecurDescription.everyDay" }));
    } else {
      parts.push(
        intl.formatMessage(
          { id: "models.article.webinarRecurDescription.everyNDays" },
          { count: article.webinarRecurRepeatInterval }
        )
      );
    }
  } else if (article.webinarRecurType === WebinarRecurTypeEnum.WEEKLY) {
    if (article.webinarRecurRepeatInterval === 1) {
      parts.push(intl.formatMessage({ id: "models.article.webinarRecurDescription.everyWeek" }));
    } else {
      parts.push(
        intl.formatMessage(
          { id: "models.article.webinarRecurDescription.everyNWeeks" },
          { count: article.webinarRecurRepeatInterval }
        )
      );
    }

    const weekdays = (article.webinarRecurWeeklyDays ?? []).map(day =>
      intl.formatMessage({ id: `models.article.webinarRecurDescription.weekdaysPlural.${day}` })
    );

    parts.push(joinListWithCommasAndAnd(weekdays, intl));
  } else if (article.webinarRecurType === WebinarRecurTypeEnum.MONTHLY) {
    if (article.webinarRecurRepeatInterval === 1) {
      parts.push(intl.formatMessage({ id: "models.article.webinarRecurDescription.everyMonth" }));
    } else {
      parts.push(
        intl.formatMessage(
          { id: "models.article.webinarRecurDescription.everyNMonths" },
          { count: article.webinarRecurRepeatInterval }
        )
      );
    }

    if (article.webinarRecurMonthlyType === WebinarRecurMonthlyTypeEnum.DAY_OF_MONTH) {
      parts.push(
        intl.formatMessage(
          { id: "models.article.webinarRecurDescription.dayOfMonth" },
          { day: ordinalSuffixOf(article.webinarRecurMonthlyDay) }
        )
      );
    } else {
      parts.push(
        intl.formatMessage(
          {
            id: `models.article.webinarRecurDescription.monthlyWeek.${article.webinarRecurMonthlyWeek}`
          },
          {
            weekday: intl.formatMessage({
              id: `models.article.webinarRecurWeekdayOptions.${article.webinarRecurMonthlyWeekday}`
            })
          }
        )
      );
    }
  }

  // Ok this is a little bit yucky but it's easier than making sure to put a comma at the end
  // of every possible last entry prior to this one. I can't put the comma at the beginning of
  // the next part because then we'd have a space before it due to the join.
  parts[parts.length - 1] += ",";

  parts.push(
    intl.formatMessage(
      { id: "models.article.webinarRecurDescription.repeatsNTimes" },
      { count: article.webinarOccurrenceRemainingSiblings }
    )
  );

  return parts.join(" ");
};
